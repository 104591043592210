import React, { useState } from 'react';
// import '../index.css';
import { useQuery } from '@apollo/react-hooks';
// import { GetAskerQuestionnaireById } from '../queries/gql-full-asker-questionnaire-page.js';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form, FormGroup, Button, Row, Col, Popover, OverlayTrigger, Badge } from 'react-bootstrap';
import { RHFInput } from 'react-hook-form-input'; //to try to deal with clash with bootstrap controlled element leading to React giving "Warning..."

function CreateQuestionnaire(props) {

  //hooks
  const { register, handleSubmit, errors, setValue } = useForm({ firstname: '' });
  const [savedInDb, setSavedInDb] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [newAskerQuestionnaireId, setNewAskerQuestionnaireId] = useState(null);

  // this plus first question (which is it's own thing to make requiring the field easier) make a max of 8 questions
  const qs = [2, 3, 4, 5, 6, 7, 8];

  const onSubmit = (formData) => {
    setSubmitting(true);

    // preprocess into cleaner request
    let questions_arr = [];
    questions_arr.push(formData.firstquestion);
    formData.questions.map((val) => {
      if(val)
        questions_arr.push(val);
    });
    const formDataNew = {...formData, questions: questions_arr};

    fetch('/nodeapp/create', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      dataType: 'application/json',
      body: JSON.stringify(formDataNew),
    }).then((response) => {
      return response.json();
    }).then((myJson) => {
      setNewAskerQuestionnaireId(myJson.new_aqid);
    });
  }

  if (submitting) {
    if (newAskerQuestionnaireId) {
      const new_questionnaire_link = window.location.origin + "/aq/" + newAskerQuestionnaireId;
      return (
        <Container>
          <Card className="m-4" bg="light">
            <Card.Body>
              <Card.Text>
                Created! Link: <a href={new_questionnaire_link}>{new_questionnaire_link}</a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      );
    }
    else
      return (<div>Submitting...</div>);
  }
  else
    return (
      <Container>
        <Card className="m-4" bg="light">
          <Card.Header>
            <Card.Text><strong>Create New Questionniare</strong></Card.Text>
          </Card.Header>
          <Card.Body>
            <Card.Text className="mb-4">
              This tool is for creating a new questionnaire. Once created, you can share it with others, and they can clone it too. Minimum 1 question. Leave unused question slots blank.
            </Card.Text>

            <Form onSubmit={handleSubmit(onSubmit)}>

            <FormGroup>
              <Form.Label>Questionnaire Name</Form.Label>
              <RHFInput
                as={<Form.Control isInvalid={!!errors.name} />}
                name="name"
                register={register}
                rules={{ required: true }}
                setValue={setValue}
              />
              {errors.name &&
                <Form.Control.Feedback type="invalid">
                  {errors.name.message || 'Required'}
                </Form.Control.Feedback>
              }
            </FormGroup>

            <FormGroup>
              <Form.Label>Description</Form.Label>
              <RHFInput
                as={<Form.Control isInvalid={!!errors.description} />}
                name="description"
                register={register}
                rules={{ required: true }}
                setValue={setValue}
              />
              {errors.description &&
                <Form.Control.Feedback type="invalid">
                  {errors.description.message || 'Required'}
                </Form.Control.Feedback>
              }
            </FormGroup>

            <FormGroup>
              <Form.Label>Intro</Form.Label>
              <RHFInput
                as={<Form.Control isInvalid={!!errors.intro} />}
                name="intro"
                register={register}
                rules={{ required: true }}
                setValue={setValue}
              />
              {errors.intro &&
                <Form.Control.Feedback type="invalid">
                  {errors.intro.message || 'Required'}
                </Form.Control.Feedback>
              }
            </FormGroup>

              <FormGroup key="1">
                <Form.Label>Question 1:</Form.Label>
                <RHFInput
                  as={<Form.Control isInvalid={!!errors.firstquestion} />}
                  name="firstquestion"
                  register={register}
                  rules={{ required: true }}
                  setValue={setValue}
                />
                {errors.firstquestion &&
                  <Form.Control.Feedback type="invalid">
                    {errors.firstquestion.message || 'Required to have at least the first question.'}
                  </Form.Control.Feedback>
                }
              </FormGroup>

              {qs.map((qnum) => {
                return (
                  <FormGroup key={qnum}>
                    <Form.Label>Question {qnum}:</Form.Label>
                    <RHFInput
                      as={<Form.Control />}
                      name={`questions[${qnum}]`}
                      register={register}
                      setValue={setValue}
                    />
                  </FormGroup>
                )
              })}

              <FormGroup controlId="formBasicCheckbox">
                <Form.Check type="checkbox" inline name="make_me_one" label="This checkbox does nothing on this page!" ref={register} />
              </FormGroup>
              <Button type="submit">Submit</Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
}

export default CreateQuestionnaire;