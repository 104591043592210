import { gql } from "apollo-boost";

export const GetAskerQuestionnairesByAskerId = gql`
query askerQuestionnairesByAskerId($askerId: Int!) {
  allAskerQuestionnaires(condition: {askerId: $askerId}) {
    totalCount
    nodes {
      questionnaireByQuestionnaireId {
        name
        description
        intro
      }
      id
      openedDatetime
      responsesByAskerQuestionnaireId {
        totalCount
      }
    }
  }
}`;