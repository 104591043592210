import React from 'react';
// import '../index.css';
import { useQuery } from '@apollo/react-hooks';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { Card, Container } from 'react-bootstrap';
import { GetResponsesByResponderId } from '../queries/gql-responses-given-by-user-id.js';
import Moment from 'react-moment';
import 'moment-timezone';

function MyResponsesGiven(props) {

  let responderId = props.responder_id;

  //hooks
  const { loading, error, data } = useQuery(GetResponsesByResponderId, {
    variables: { responderId: responderId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  let rs_raw = data.allResponses.nodes;

  // make a nice clean askerQuestionnaire array
  const rs = [];
  const rs_count = data.allResponses.totalCount;
  rs_raw.forEach((r_raw,key) => {
    rs[key] = {};
    rs[key].id = r_raw.id;
    rs[key].asker_firstname = r_raw.askerQuestionnaireByAskerQuestionnaireId.userByAskerId.firstName;
    rs[key].asker_email = r_raw.askerQuestionnaireByAskerQuestionnaireId.userByAskerId.email;
    rs[key].aqid = r_raw.askerQuestionnaireId;
    rs[key].responseDatetime = r_raw.responseDatetime;
    rs[key].openedDatetime = r_raw.askerQuestionnaireByAskerQuestionnaireId.openedDatetime;
    rs[key].questionnaire_name = r_raw.askerQuestionnaireByAskerQuestionnaireId.questionnaireByQuestionnaireId.name;
    rs[key].questionnaire_description = r_raw.askerQuestionnaireByAskerQuestionnaireId.questionnaireByQuestionnaireId.description;
    rs[key].questionnaire_intro = r_raw.askerQuestionnaireByAskerQuestionnaireId.questionnaireByQuestionnaireId.intro;
    rs[key].qas = [];
    r_raw.answersByResponseId.nodes.forEach((qa_raw, key2) => {
      rs[key].qas[key2] = {};
      rs[key].qas[key2].question = qa_raw.questionByQuestionId.question;
      rs[key].qas[key2].answer = qa_raw.answer;
    })
  });

  return (
    <Container>
      <Card className="m-4" bg="light">
        <Card.Header>
          <Card.Text><strong>My Responses ({rs_count})</strong></Card.Text>
        </Card.Header>
        <Card.Body>
          {rs.map((r) => {
            return (
              <Card className="mb-3" key={"key" + r.id} >
                <Card.Body>
                  <p>To <strong>{r.asker_firstname}</strong> on <strong><Moment format="YYYY-MM-DD HH:mm">{r.responseDatetime}</Moment></strong></p>
                  <p><strong>Questionnaire: </strong>{r.questionnaire_name}</p>
                  <p><strong>Opened on: </strong><Moment format="YYYY-MM-DD HH:mm">{r.openedDatetime}</Moment></p>
                  <ol>
                    {r.qas.map((qa, key) => {
                      return (
                        <li key={key}>
                          {qa.question}<br />
                          {qa.answer}<br />
                        </li>
                      )
                    })}
                  </ol>
                  <p><strong>Questionnaire Link: </strong><Link to={"/aq/" + r.aqid}>{window.location.host + "/aq/" + r.aqid}</Link></p>
                </Card.Body>
              </Card>
            )
          })}
        </Card.Body>
      </Card>
    </Container>
  );
}
  
export default MyResponsesGiven;