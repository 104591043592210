import { gql } from "apollo-boost";

export const GetAnswersByAskerQuestionnaireId = gql`
query answersByAskerQuestionnaireId($aqid: Int!){
  askerQuestionnaireById(id: $aqid) {
    responsesByAskerQuestionnaireId {
      totalCount
      nodes {
        responderId
        userByResponderId {
          firstName
          id
        }
        responseDatetime
        answersByResponseId {
          nodes {
            answer
            questionByQuestionId {
              question
            }
          }
        }
      }
    }
    questionnaireByQuestionnaireId {
      name
      intro
      description
      questionnaireQuestionsByQuestionnaireId {
        nodes {
          questionByQuestionId {
            question
          }
        }
      }
    }
  }
}`;