import React, { useState, useEffect, useRef } from 'react';
// import '../index.css';
import { useQuery } from '@apollo/react-hooks';
import { GetAskerQuestionnaireById } from '../queries/gql-full-asker-questionnaire-page.js';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container, Form, FormGroup, Button, Row, Col, Popover, OverlayTrigger, Badge, Alert } from 'react-bootstrap';
import { RHFInput } from 'react-hook-form-input'; //to try to deal with clash with bootstrap controlled element leading to React giving "Warning..."

function CopyLinkBox(props) {

  const [copySuccess, setCopySuccess] = useState('');
  const inputRef = useRef(null);

  function copyToClipboard(e) {
    inputRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('Copied!');
    setTimeout(() => {setCopySuccess('')}, 1000);
  };

  return (
    <div>
        <input
          ref={inputRef}
          value={props.linkToCopy}
        />
      {
        /* Logical shortcut for only displaying the 
           button if the copy command exists */
        document.queryCommandSupported('copy') &&
          <>
          <button onClick={copyToClipboard}>Copy</button> 
          <span> {copySuccess}</span>
          </>
       }
    </div>
   );

}

function AskerQuestionnaire(props) {
  let askerQuestionnaireId = parseInt(useParams().aqid);

  //hooks
  const { loading, error, data } = useQuery(GetAskerQuestionnaireById, {
    variables: { aqid: askerQuestionnaireId },
  });
  const [savedInDb, setSavedInDb] = useState(false);
  const [clonedQuestionnaireId, setClonedQuestionnaireId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm({ firstname: '' });

  useEffect(() => {
    setTimeout(() => {
      if (props.authenticated) {
        setValue('email', props.answerer_email);
        setValue('firstname', props.answerer_firstname);
      }
    }, 300);  //pretty lame I think, but couldn't figure out how to consistently make these two fields populate without this delay
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const qs = data.askerQuestionnaireById.questionnaireByQuestionnaireId.questionnaireQuestionsByQuestionnaireId.nodes;
  const askerName = data.askerQuestionnaireById.userByAskerId.firstName;
  // const questionnaireName = data.askerQuestionnaireById.questionnaireByQuestionnaireId.name;
  // const questionnaireDescription = data.askerQuestionnaireById.questionnaireByQuestionnaireId.description;
  const questionnaireIntro = data.askerQuestionnaireById.questionnaireByQuestionnaireId.intro;

  const onSubmit = (formData) => {
    setSubmitting(true);

    let answersArr = [];
    formData.answers.map((val, index) => { return answersArr.push({ qid: index, answer_text: val }); }); //the return or the push seems redundant
    const formDataNew = { ...formData, answers: answersArr }

    fetch('/nodeapp/aq', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      dataType: 'application/json',
      body: JSON.stringify(formDataNew),
    }).then((response) => {
      return response.json();
    }).then((myJson) => {
      setClonedQuestionnaireId(myJson.new_aqid);
      setSavedInDb(true);     //Assuming there are no errors, but I need to still check how to do that.
      // return <div>answers sent, thanks</div>;  //does this need to be here?
    });
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Clone this Questionnaire</Popover.Title>
      <Popover.Content>
        If checked, then after submitting you'll get your own version of this questionnaire with the same questions. Then <em>you</em> can send it out to people and collect their answers to you.
          </Popover.Content>
    </Popover>
  );

  if (submitting) {
    if (savedInDb)
      if (clonedQuestionnaireId) {
        const new_questionnaire_link = window.location.origin + "/aq/" + clonedQuestionnaireId;
        return (
          <Container>
            <Card className="m-4" bg="light">
              <Card.Body>
                <Card.Text>
                  Response sent. Thank you!
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="m-4" bg="light">
              <Card.Header>
                <Card.Title className="mb-0">Also, your own questionnaire is ready!</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Your new questionnaire link is:
                  <br /><br />
                        <CopyLinkBox linkToCopy={new_questionnaire_link} />
                  <br />
                  Send out the link and receive an email whenever someone responds.
                </Card.Text>
                <Alert className="mt-4" variant="info">
                  <em>Tip: Send it back to the person you just responded to!</em>
                </Alert>
              </Card.Body>
            </Card>
          </Container>
        );
      }
      else
        return (
          <Container>
            <Card className="m-4" bg="light">
              <Card.Body>
                <Card.Text>
                  Response sent. Thank you!
                </Card.Text>
              </Card.Body>
            </Card>
          </Container>
        );
    else
      return (<div>Submitting...</div>);
  }
  else
    return (
      <Container>
        <Card className="m-4" bg="light">
          <Card.Header>
            <Card.Text><strong>Questionnaire from {askerName}</strong></Card.Text>
          </Card.Header>
          <Card.Body>
            <Card.Text className="mb-4">
              {questionnaireIntro}
            </Card.Text>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <input type='hidden' name='aqid' value={askerQuestionnaireId} ref={register} />
              {qs.map((q) => {
                return (
                  <FormGroup key={q.questionByQuestionId.id}>
                    <Form.Label>{q.questionByQuestionId.question}</Form.Label>
                    <RHFInput
                      as={<Form.Control />}
                      name={`answers[${q.questionByQuestionId.id}]`}
                      register={register}
                      setValue={setValue}
                    />
                  </FormGroup>
                )
              })}
              <Row>
                <Col>
                  <FormGroup>
                    <Form.Label>Your First Name</Form.Label>
                    <RHFInput
                      as={<Form.Control isInvalid={!!errors.firstname} />}
                      name="firstname"
                      register={register}
                      rules={{ required: true }}
                      setValue={setValue}
                    />
                    {errors.firstname &&
                      <Form.Control.Feedback type="invalid">
                        {errors.firstname.message || 'Required'}  {/* not sure why the ".message ||" */}
                      </Form.Control.Feedback>
                    }
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label>Your Email</Form.Label>
                    <RHFInput
                      as={<Form.Control type="email" isInvalid={!!errors.email} />}
                      name="email"
                      placeholder="Enter email"
                      register={register}
                      rules={{ required: true }}
                      setValue={setValue}
                    />
                    {errors.email &&
                      <Form.Control.Feedback type="invalid">
                        {errors.email.message || 'Required'}  {/* not sure why the ".message ||" */}
                      </Form.Control.Feedback>
                    }
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup controlId="formBasicCheckbox">
                <Form.Check type="checkbox" inline name="make_me_one" label="Make one of these questionnaires for me!" ref={register} />
                <OverlayTrigger inline focus={popover} placement="top" overlay={popover}>
                  <Badge style={{ fontSize: 14, fontStyle: "italic", fontWeight: "normal", color: "grey" }} className="pl-0" variant="light" >(What's this?)</Badge>
                </OverlayTrigger>
              </FormGroup>
              <Button type="submit">Submit</Button>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
}

export default AskerQuestionnaire;