import React from 'react';
// import '../index.css';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Container } from 'react-bootstrap';
import { GetAnswersByAskerQuestionnaireId } from '../queries/gql-answers-by-asker-questionnaire-id.js';
import Moment from 'react-moment';
import 'moment-timezone';

function AskerQuestionnaireAnswers() {

  let askerQuestionnaireId = parseInt(useParams().aqid);

  //hooks
  const { loading, error, data } = useQuery(GetAnswersByAskerQuestionnaireId, {
    variables: { aqid: askerQuestionnaireId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  // questionnaire: get a clean questionnaire object
  const questionnaire = {};
  questionnaire.name = data.askerQuestionnaireById.questionnaireByQuestionnaireId.name;
  questionnaire.intro = data.askerQuestionnaireById.questionnaireByQuestionnaireId.intro;
  questionnaire.description = data.askerQuestionnaireById.questionnaireByQuestionnaireId.description;
  questionnaire.questions = [];
  const raw_questions = data.askerQuestionnaireById.questionnaireByQuestionnaireId.questionnaireQuestionsByQuestionnaireId.nodes;
  raw_questions.forEach((q,key) => {
    questionnaire.questions[key] = q.questionByQuestionId.question;
  });

  // responses: get nice clean array of responses, rs
  const raw_responses = data.askerQuestionnaireById.responsesByAskerQuestionnaireId.nodes;
  const rs = [];
  raw_responses.forEach((r, key) => {
    rs[key] = {};
    rs[key].firstname = r.userByResponderId.firstName;
    rs[key].responseDatetime = r.responseDatetime;
    rs[key].qas = [];
    r.answersByResponseId.nodes.forEach((qa,key2) => {
      rs[key].qas[key2] = {};
      rs[key].qas[key2].question = qa.questionByQuestionId.question;
      rs[key].qas[key2].answer = qa.answer;
    })
  });

  return (
    <Container>
      <Card className="m-4" bg="light">
        <Card.Header>
          <Card.Text><strong>Questionnaire: {questionnaire.name}</strong></Card.Text>
        </Card.Header>
        <Card.Body>
          <p>Name: {questionnaire.name}</p>
          <p>Description: {questionnaire.description}</p>
          <p>Intro: {questionnaire.intro}</p>
          <p>Responses:</p>
          {rs.map((r) => {
            return (
              <Card className="m-3 p-3">
                <p>From <strong>{r.firstname}</strong> on <strong>
                  <Moment format="YYYY-MM-DD HH:mm">{r.responseDatetime}</Moment></strong></p>
                <ol>
                  {r.qas.map((qa) => {
                    return (
                      <>
                        <li>
                          {qa.question}<br />
                          {qa.answer}<br />
                        </li>
                      </>
                    )
                  })}
                </ol>
              </Card>
            )
          })}
        </Card.Body>
      </Card>
    </Container>
  );
}
  
export default AskerQuestionnaireAnswers;