import React from 'react';
// import '../index.css';
import { useQuery } from '@apollo/react-hooks';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { Card, Container } from 'react-bootstrap';
import { GetAskerQuestionnairesByAskerId } from '../queries/gql-asker-questionnaires-by-user-id.js';
import Moment from 'react-moment';
import 'moment-timezone';

function MyAskerQuestionnaires(props) {

  let askerId = props.asker_id;

  //hooks
  const { loading, error, data } = useQuery(GetAskerQuestionnairesByAskerId, {
    variables: { askerId: askerId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const aqs_count = data.allAskerQuestionnaires.totalCount;

  // make a nice clean askerQuestionnaire array
  const aqs = [];
  data.allAskerQuestionnaires.nodes.forEach((aq_raw,key) => {
    aqs[key] = {};
    aqs[key].id = aq_raw.id;
    aqs[key].count = aq_raw.responsesByAskerQuestionnaireId.totalCount;
    aqs[key].name = aq_raw.questionnaireByQuestionnaireId.name;
    aqs[key].description = aq_raw.questionnaireByQuestionnaireId.description;
    aqs[key].intro = aq_raw.questionnaireByQuestionnaireId.intro;
    aqs[key].openedDatetime = aq_raw.openedDatetime;
  });

  return (
    <Container>
      <Card className="m-4" bg="light">
        <Card.Header>
          <Card.Text><strong>My Questionnaires ({aqs_count})</strong></Card.Text>
        </Card.Header>
        <Card.Body>
          {aqs.map((aq) => {
                  return (
                    <Card className="mb-3" key={"key"+aq.id} >
                      <Card.Body>
                        <p><strong>Name: </strong>{aq.name}</p>
                        <p><strong>Description: </strong>{aq.description}</p>
                        <p><strong>Intro: </strong>{aq.intro}</p>
                        <p><strong>Opened on: </strong><Moment format="YYYY-MM-DD HH:mm">{aq.openedDatetime}</Moment></p>
                        <p><strong>Personal Questionnaire Link: </strong><Link to={"/aq/"+aq.id}>{window.location.host+"/aq/"+aq.id}</Link></p>
                        <p><strong>Responses: </strong><Link to={"/u/aq/"+aq.id+"/responses"}>See Responses ({aq.count})</Link></p>
                      </Card.Body>
                    </Card>
                  )
                })}
        </Card.Body>
      </Card>
    </Container>
  );
}
  
export default MyAskerQuestionnaires;