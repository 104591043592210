import { gql } from "apollo-boost";

export const GetAskerQuestionnaireById = gql`
  query askerQuestionnaireById($aqid: Int!){
    askerQuestionnaireById(id: $aqid){
    userByAskerId{
      firstName
      familyName
    }
    questionnaireByQuestionnaireId{
      name
      description
      intro
      questionnaireQuestionsByQuestionnaireId{
        nodes{
          questionByQuestionId{
            id
            question
          }
        }
      }
    }
  }
}`;