import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './App';
import * as serviceWorker from './serviceWorker';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { Router, Route, useParams, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { LinkContainer } from 'react-router-bootstrap';
import GoogleButton from 'react-google-button';
import AskerQuestionnaire from './components/AskerQuestionnaire';
import AskerQuestionnaireAnswers from './components/AskerQuestionnaireAnswers';
import MyAskerQuestionnaires from './components/MyAskerQuestionnaires';
import MyResponsesGiven from './components/MyResponsesGiven';
import CreateQuestionnaire from './components/CreateQuestionnaire';

//Google Analytics
ReactGA.initialize("UA-45811827-3");
const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const client = new ApolloClient(); // add this if need to specify, otherwise set it in proxy { uri: 'http://localhost:5000/graphql' }

//GS hack for development: Redirect uncaught routes (i.e. catchall) to port 5000 to be handled by express. (note this will cause an endless loop for non-specified routes because express is doing the same.)
function Redir() {
  let url = useParams()[0];
  //if we're in React's dev server (port 3000), then redirect this request to express (port 5000), otherwise do nothing
  if (window.location.origin === 'http://localhost:3000')
    window.location.assign('http://localhost:5000/' + url);
  return (
    <div> </div>
  );
}

function App() {

  useEffect(() => {
    fetch("/auth/login/success", {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true
      }
    })
      .then(response => {
        if (response.status === 200)
          return response.json();
        else
          throw new Error("server response may be abnormal");
      })
      .then(responseJson => {
        if (responseJson.success) {
          setUser(responseJson.user);
          setAuthenticated(true);
        }
        else {
          setAuthenticated(false);
          setAuthenticationError('User is not authenticated. (not logged in)');
        }
      })
      .catch(error => {
        setAuthenticated(false);
        setAuthenticationError('Failed to authenticate user: ' + error);
      });
    ReactGA.pageview(window.location.pathname);
  }, [])  // putting [] here means run this just first time, equivalent to componentDidMount

  const [authenticated, setAuthenticated] = useState(false);
  const [authenticationError, setAuthenticationError] = useState(null);
  const [user, setUser] = useState(null);

  // I just put this here for now so it doesn't throw a warning that the variable isn't being used.
  if (authenticationError)
    console.log(authenticationError);

  return (
    <Router history={history}>
      <ApolloProvider client={client}>
        <Navbar bg="dark" variant="dark" expand="md" className="justify-content-between">
          <Navbar.Brand href="/">My Sideline</Navbar.Brand>
            {authenticated &&
            <Navbar.Text>Hi, <strong>{user.first_name}</strong>!<span> </span></Navbar.Text>}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
            {authenticated &&
              <Nav className="mr-auto">
                <LinkContainer to="/u/aqs"><Nav.Link>My Questionnaires</Nav.Link></LinkContainer>
                <LinkContainer to="/u/rs"><Nav.Link>My Responses</Nav.Link></LinkContainer>
              </Nav>
              }
            {authenticated &&
              <Nav>
                <Nav.Link href="/auth/logout">Logout</Nav.Link>
              </Nav>}
            {!authenticated &&
              <Nav>
                <LinkContainer to="/login">
                  <Nav.Link>Signup/Login</Nav.Link>
                </LinkContainer>
              </Nav>}
          </Navbar.Collapse>
        </Navbar>
        {/* the Switch is so that no two routes will both show up, and mainly so I can use the last route as a catchall */}
        <Switch>
          <Route exact={true} path="/">
            {!authenticated &&
              <Container>
                <Row className="justify-content-md-center">
                  <Col className="m-3" md="auto">
                    <GoogleButton onClick={() => { window.location.assign('/auth/google') }} />
                  </Col>
                </Row>
              </Container>}
            {authenticated && 
              <Container className="text-center">Welcome.</Container>
            }
          </Route>
          <Route path={'/aq/:aqid'}>
            {user && <AskerQuestionnaire authenticated={true} answerer_email={user.email} answerer_firstname={user.first_name} />}
            {!user && <AskerQuestionnaire />}
          </Route>
          <Route path={'/login'}>
            <Container>
              <Row className="justify-content-md-center">
                <Col className="m-3" md="auto">
                  <GoogleButton onClick={() => { window.location.assign('/auth/google') }} />
                </Col>
              </Row>
            </Container>
          </Route>
          <Route path={'/u/aqs'}>
            {user && <MyAskerQuestionnaires asker_id={user.id} />}
          </Route>
          <Route path={'/u/rs'}>
            {user && <MyResponsesGiven responder_id={user.id} />}
          </Route>
          <Route path={'/u/aq/:aqid/responses'}>
            <AskerQuestionnaireAnswers />
          </Route>
          <Route path={'/u/create'}>
            <CreateQuestionnaire />
          </Route>
          <Route path={'/*'}>
            <Redir />
            {/* GS: My hack for better dev env. See comments near function component above */}
          </Route>
        </Switch>
      </ApolloProvider>
    </Router>
  );
}


/*         
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="#home">
            My Sideline
          </Navbar.Brand>
        </Navbar> */

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
