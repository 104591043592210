import { gql } from "apollo-boost";

export const GetResponsesByResponderId = gql`
query responsesByResponderId($responderId: Int!){
  allResponses(condition: {responderId: $responderId}) {
    totalCount
    nodes {
      id
      askerQuestionnaireId
      responseDatetime
      askerQuestionnaireByAskerQuestionnaireId {
        userByAskerId {
          firstName
          email
        }
        openedDatetime
        questionnaireByQuestionnaireId {
          name
          description
          intro
        }
      }
      answersByResponseId {
        nodes {
          answer
          questionByQuestionId {
            question
          }
        }
      }
    }
  }
}`;